@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@400;500;600;700&display=swap');

@layer base {

  html {
    font-family: 'Raleway', sans-serif;
  }

  body {
    background-image: linear-gradient(to bottom, #1f2937, #000000, #1f2937);
  }

  li {
    @apply px-4;
    @apply cursor-pointer
  }
}